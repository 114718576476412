<template>
  <base-modal :dialogClass="dialogClass">
    <h1>
      <slot name="title"></slot>
    </h1>
    <h2>
      <slot name="description"></slot>
    </h2>

    <button class="modal-button cancel-button" @click.prevent="onCancel" :style="buttonStyle">
      <slot name="cancel"></slot>
    </button>
    <button class="modal-button confirm-button" @click.prevent="onConfirm" :style="buttonPrimaryStyle">
      <slot name="confirm"></slot>
    </button>
  </base-modal>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseModal from '@/components/ui/BaseModal.vue'
import { themeColors } from '@/common/shared.js'

export default {
  components: {
    BaseModal,
  },
  props: {
    confirmFunc: {
      type: Function,
      required: true,
    },
    dialogClass: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const store = useStore()

    const onConfirm = () => {
      props.confirmFunc()
      context.emit('close')
    }

    const onCancel = () => {
      context.emit('close')
    }

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
        borderColor: themeColors[store.getters.currentThemeName].modalTextColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const buttonPrimaryStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].buttonBorderColor,
        color: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    return {
      buttonStyle,
      buttonPrimaryStyle,
      onCancel,
      onConfirm,
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  padding-top: 1rem;
}

h2 {
  font-size: 1.5rem;
}

.modal-button {
  font: inherit;
  margin: 1rem 3rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 40px;


  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

:focus:not(:focus-visible) {
  transform: scale(1);
}

.cancel-button {
  margin: 1rem 3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 40px;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

.confirm-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>